import React from 'react'

import Check from '../../assets/wh-check.png'
import QuoteLeft from '../../assets/quote-left.png'
import QuoteRight from '../../assets/quote-right.png'

import styles from '../../styles/info-site/ProductReview.module.css'


const ProductReview = ({ left, right}) => {

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.left}>
          <h2>
            {left.title}
          </h2>
          <p>
            <span><img className={`${styles.quote} ${styles.quoteLeft}`} src={QuoteLeft} alt='' /></span>{left.quote}<span><img className={`${styles.quote} ${styles.quoteRight}`} src={QuoteRight} alt='' /></span>
          </p>

          <img className={styles.logo} src={left.logo} alt='' />
        </div>
        <div className={styles.right}>
          <h2>
            {right.title}
          </h2>
          <ul className={styles.list}>
            {
              right.list.map((item, i) => (
                <li key={i} className={styles.listItem}>
                  <img src={Check} alt='' />
                  <p>{item}</p>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProductReview